<template>
    <i
        class="o-icon"
        :class="className"
    >
        <svg :class="`svg-${icon}`">
            <title v-if="title">{{ title }}</title>
            <use v-bind:xlink:href="path"></use>
        </svg>
    </i>
</template>

<script>

export default {
    name: 'Icon',
    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: '',
        },
    },
    computed: {
        path() {
            return `/dist/svg/sprite.svg#svg-${this.icon}`;
        },
        className() {
            let name = `o-icon--${this.icon}`
            name = this.size ? `${name} o-icon--${this.size}`: name
            return name;
        }
    },
};

</script>

<style lang="scss">

.o-icon {
    display: inline-block;
    vertical-align: middle;

    svg {
        display: block;
        fill: currentColor;
    }
}


/*----------  SVG Sizes  ----------*/

// // Socials
// .svg-instagram {
//     width: 1em;
//     height: 1em;
// }

</style>
