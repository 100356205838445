<template>
    <footer class="l-footer">
        <span class="l-footer__copy">&copy; {{ year }}</span>
        <a
            href="http://mambomambo.ca"
            title="MamboMambo studio de design graphique et web à Québec"
            rel="noopener"
            class="l-footer__mambo"
        >MamboMambo</a>
    </footer>
</template>

<script>

export default {
    name: 'AppFooter',
    computed: {
        year: () => new Date().getFullYear(),
    },
};

</script>

<style lang="scss">

.l-footer {}

.l-footer__copy {}

.l-footer__mambo {}

</style>
