/*
** Loader
*/

// State
const state = {
    loadingTasks: 0,
}

// Getters
const getters = {
    isLoading: state => state.loadingTasks > 0,
    loadCount: state => state.loadingTasks,
}

// Actions
const actions = {
    startLoad(store) {
        store.commit('updateLoad', store.state.loadingTasks + 1)
    },
    endLoad(store) {
        store.commit('updateLoad', store.state.loadingTasks - 1)
    },
    resetLoad(store) {
        store.commit('updateLoad', 0)
    },
}

// Mutations
const mutations = {
    updateLoad(state, value) {
        state.loadingTasks = value
        console.log('updateLoad', value)
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
