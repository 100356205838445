<template>
    <div>
        <h1>
            {{ page.headline }}
        </h1>
    </div>
</template>

<script>
import AppSection from 'layout/AppSection';

export default {
    name: 'BlogPage',
    components: {
        AppSection
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seoPage ) ? this.page.seoPage.title : null
        }
    },
    created() {
    }
};

</script>

<style lang="scss">

</style>
