<template>
    <header v-if="nav" class="l-header">
        <nav class="l-nav" itemscope itemtype="http://schema.org/SiteNavigationElement">
            <ul
                v-if="nav.mainNav && nav.mainNav.length"
                class="l-nav__list"
            >
                <li
                    v-for="(item, i) in nav.mainNav"
                    :key="i"
                    class="l-nav__item"
                    itemprop="name"
                >
                    <router-link
                        :to="item.path"
                        :title='item.entry.title'
                        class="l-nav__link"
                        exact
                        itemprop="url"
                    >
                        {{ item.label ? item.label : item.entry.title }}
                    </router-link>
                </li>
            </ul>
            <ul
                v-if="nav.altNav && nav.altNav.length"
                class="l-nav__list"
            >
                <li
                    v-for="(item, i) in nav.altNav"
                    :key="i"
                    class="l-nav__item"
                    itemprop="name"
                >
                    <router-link
                        :to="item.path"
                        :title='item.entry.title'
                        class="l-nav__link"
                        exact
                        itemprop="url"
                    >
                        {{ item.label ? item.label : item.entry.title }}
                    </router-link>
                </li>
            </ul>
            <ul
                v-if="nav.bookingLink || nav.orderLink"
                class="l-nav__list">
                <li
                    v-if="nav.bookingLink"
                >
                    <a
                        :href="nav.bookingLink"
                        :title="$t('btn.bookOnline')"
                        v-html="$t('btn.bookOnline')"
                    />
                </li>
                <li
                    v-if="nav.orderLink"
                >
                    <a
                        :href="nav.orderLink"
                        :title="$t('btn.orderOnline')"
                        v-html="$t('btn.orderOnline')"
                    />
                </li>
            </ul>
        </nav>
    </header>
</template>

<script>

export default {
    name: 'AppHeader',
    data: () => ({
        navIsOpen: false,
    }),
    computed: {
        nav() {
            return this.$store.state.global.nav
        }
    },
    methods: {
        openNav() {
            this.navIsOpen = true

            this.currentScroll = window.scrollY
            document.documentElement.style.overflowY = 'hidden';
        },
        closeNav() {
            this.navIsOpen = false

            // Add previous scroll
            document.documentElement.style.overflowY = 'scroll';
            window.scrollTo(0, this.currentScroll)
        },
    },
    watch: {
        navIsOpen(isOpen) {
            this.$emit('toggleNav', isOpen)
        },
    }
};

</script>

<style lang="scss">

.l-header {}

.l-nav {}

.l-nav__list {}

.l-nav__item {}

.l-nav__link {

    &:hover {}

    &.is-current {}
}


</style>
