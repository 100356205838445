import {fetchApi} from 'src/graphql/config';
import queries from 'src/graphql/queries';
import {linkResolver} from 'src/router';

/*
** Global
*/

// State
const state = {
    nav: [],
    currentPage: {},
};

// Getters
const getters = {
    getCurrentPage: state => state.currentPage,
};

// Actions
const actions = {
    // Initial load for navigation, etc...
    initLoad(store) {

        // Start load
        store.dispatch('loader/startLoad', null, {root: true});

        return Promise.all([
            store.dispatch('loadNav')
        ]).then(() => {

            // End load
            store.dispatch('loader/endLoad', null, {root: true});
        })
    },
    loadNav(store) {

        return new Promise((resolve, reject) => {

            fetchApi(queries.nav)
                .then(data => {

                    // Format nav and add route path to each nav item
                    const mainNav = data.globalSet.mainNav
                    const altNav = data.globalSet.altNav
                    const bookingLink = data.globalSet.bookingLink
                    const orderLink = data.globalSet.orderLink

                    mainNav.forEach(item => {
                        item.entry = item.entry[0]
                        item.path = linkResolver(item.entry.sectionHandle)
                    });

                    altNav.forEach(item => {
                        item.entry = item.entry[0]
                        item.path = linkResolver(item.entry.sectionHandle)
                    });

                    const nav = {
                        mainNav: mainNav,
                        altNav: altNav,
                        bookingLink: bookingLink,
                        orderLink: orderLink
                    }
                    store.commit('setNav', nav);
                    resolve(nav);
                });
        })
    },
    loadContent(store, to) {

        return new Promise((resolve, reject) => {

            // Timer in seconds
            const timer = new Date

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Load page content
            let fetchContent;
            const handle = to.meta.section;
            const type = to.meta.type;

            // For beers, use specific dispatch
            if (type === 'beer') {
                const slug = to.params.slug;
                fetchContent = store.dispatch('beers/loadBeer', { handle: handle, slug: slug }, {root: true});

            // For channel entries, load specific page with id
            } else if (type === 'channel') {
                const slug = to.params.slug;
                fetchContent = store.dispatch('pages/loadEntry', { handle: handle, slug: slug }, {root: true});

            // For single entries, load page with handle
            } else {
                fetchContent = store.dispatch('pages/loadSingle', handle, {root: true});
            }

            fetchContent.then((page) => {
                // Duration in ms of the fetchContent + 200ms for the loader cover delay (see loader component)
                let delay = (timer - new Date) + 200
                delay = delay < 0 ? 0 : delay

                setTimeout(() => {
                    store.commit('setPage', page);
                    resolve(page);

                    // Timeout to let template to render data
                    setTimeout(() => {
                        store.dispatch('loader/endLoad', null, {root: true});
                    }, 10);

                }, delay);
            });

        });
    },
    updatePageName(store, name) {
        store.commit('setPageName', name)
    },
};

// Mutations
const mutations = {
    setNav(state, nav) {
        state.nav = nav
    },
    setPage(state, page) {
        state.currentPage = page;
    },
    setPageName(state, name) {
        state.currentPageName = name
    },
};


// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
