import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from 'src/store/index'
import view from './directives/view'
import { i18n } from 'src/i18n'
import VueMeta from 'vue-meta'
import VueAnalytics from 'vue-analytics';
//import * as VueGoogleMaps from 'vue2-google-maps'

import 'src/filters'


// Var
Vue.config.productionTip = false

// Meta descriptions
Vue.use(VueMeta, {})

// Analytics
const isProd = process.env.NODE_ENV === 'production';
Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
    router,
    debug: {
        enabled: !isProd,
        sendHitTask: isProd,
    },
});

/*
// Google map
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAP_KEY
    }
})
*/

// Initial load
store.dispatch('global/initLoad')

// Vue instance
new Vue({
    el: '#vue',
    router,
    i18n,
    store,
    render: h => h(App),
    mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
