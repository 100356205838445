import { fetchApi } from 'src/graphql/config';
import queries from 'src/graphql/queries';
import { i18n } from 'src/i18n'

/*
** Global
*/

const parseBeer = (beer) => {

    // Parsers

    return {
        ...beer
    }
}

// State
const state = {
    beers: [],
    index: [],
};

// Getters
const getters = {
    getBeerById: state => id => state.beers[id],
    getBeerByHandle: state => section => state.beers.find(beer => beer.sectionHandle === section),
    getBeerBySlug: state => slug => state.beers.find(beer => beer.slug === slug),
    getBeersIndex: state => state.index,
};

// Actions
const actions = {
/*
    loadBeers(store, args) {
        return new Promise((resolve, reject) => {

            // Define vars from arguments
            const query = args && typeof args.query !== undefined ? args.query : ''
            const loader = args && typeof args.loader !== undefined ? args.loader : true

            // Start load
            if (loader) {
                store.dispatch('loader/startLoad', null, {root: true});
            }

            fetchApi(queries.beers(query))
                .then(data => {
                    let beers = data.entries
                    beers.forEach((beer, i) => {
                        beer = parseBeer(beer)
                        store.commit('addBeer', beer)
                        beers[i] = beer
                    })

                    resolve(beers)

                    // End load
                    if (loader) {
                        store.dispatch('loader/endLoad', null, {root: true});
                    }
                });
        })
    },
*/
    indexBeers(store, args) {
        return new Promise((resolve, reject) => {

            // Define vars from arguments
            const query = args && typeof args.query !== undefined ? args.query : ''
            const loader = args && typeof args.loader !== undefined ? args.loader : true

            // Start load
            if (loader) {
                store.dispatch('loader/startLoad', null, {root: true});
            }

            fetchApi(queries.beers(query))
                .then(data => {
                    let beers = data.entries
                    beers.forEach((beer, i) => {
                        beer = parseBeer(beer)
                        store.commit('addBeerToIndex', beer)
                        beers[i] = beer
                    })

                    resolve(beers)

                    // End load
                    if (loader) {
                        store.dispatch('loader/endLoad', null, {root: true});
                    }
                });
        })
    },
    loadBeer(store, {handle, slug}) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Search if beer already exists
            let beer = store.getters.getBeerBySlug(slug)

            // If the beer already exists, resolve with the value
            if(typeof beer !== 'undefined') {
                resolve(beer)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If the beer doesn't exist, load it
            } else {
                fetchApi(queries.beer(slug))

                    .then(r => {
                        store.commit('addBeer', r.entry)
                        resolve(r.entry)

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});
                    })
            }

            // TODO
            // ADD BEER TO INDEX IF NOT ALREADY THERE
            console.log('loadBeer::END')

        })
    }
};

// Mutations
const mutations = {
    addBeer(state, beer) {
        state.beers.push(beer)
    },
    addBeerToIndex(state, beer) {
        state.index.push(beer);
    },
};


// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
