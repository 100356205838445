import Vue from 'vue';
import VueRouter from 'vue-router'
import store from 'src/store'
import { locales, defaultLocal, currentLocal, i18n } from 'src/i18n'

import Home from 'templates/views/Home'
import BeersPage from 'templates/views/BeersPage'
import Beer from 'templates/views/Beer'
import BlogPage from 'templates/views/BlogPage'
import ContactPage from 'templates/views/ContactPage'
import EventsPage from 'templates/views/EventsPage'
import MicrobreweryPage from 'templates/views/MicrobreweryPage'
import PosPage from 'templates/views/PosPage'
import RestaurantsPage from 'templates/views/RestaurantsPage'

// Use plugin
Vue.use(VueRouter)

// Translated route paths
const paths = {
    beersPage: {
        fr: '/bieres',
        en: '/beers',
    },
    beers: {
        fr: '/bieres/:slug',
        en: '/beers/:slug',
    },
    blogPage: {
        fr: '/blogue',
        en: '/blog',
    },
    articles: {
        fr: '/blogue/:slug',
        en: '/blog/:slug',
    },
    contactPage: {
        fr: '/nous-joindre',
        en: '/contact-us',
    },
    eventsPage: {
        fr: '/evenements',
        en: '/events',
    },
    events: {
        fr: '/evenements/:slug',
        en: '/events/:slug',
    },
    eventsPage: {
        fr: '/evenements',
        en: '/events',
    },
    microbreweryPage: {
        fr: '/microbrasserie',
        en: '/microbrewery',
    },
    posPage: {
        fr: '/points-de-vente',
        en: '/sales-points',
    },
    restaurantsPage: {
        fr: '/restaurants',
        en: '/restaurants',
    },
    restaurants: {
        fr: '/restaurants/:slug',
        en: '/restaurants/:slug',
    },
}

const routes = [
    {
        name: 'home',
        title: 'Homepage',
        path: '/',
        component: Home,
        meta: {
            section: 'homepage',
            type: 'single',
        }
    },
    {
        name: 'beersPage',
        title: 'Beers Page',
        path: paths.beersPage[currentLocal],
        component: BeersPage,
        meta: {
            section: 'beersPage',
            type: 'single',
        }
    },
    {
        name: 'beers',
        title: 'Beers',
        path: paths.beers[currentLocal],
        component: Beer,
        meta: {
            section: 'beers',
            type: 'beer',
        }
    },
    {
        name: 'blogPage',
        title: 'Blog Page',
        path: paths.blogPage[currentLocal],
        component: BlogPage,
        meta: {
            section: 'blogPage',
            type: 'single',
        }
    },
    {
        name: 'contactPage',
        title: 'Contact Page',
        path: paths.contactPage[currentLocal],
        component: ContactPage,
        meta: {
            section: 'contactPage',
            type: 'single',
        }
    },
    {
        name: 'eventsPage',
        title: 'Events Page',
        path: paths.eventsPage[currentLocal],
        component: EventsPage,
        meta: {
            section: 'eventsPage',
            type: 'single',
        }
    },
    {
        name: 'microbreweryPage',
        title: 'Microbrewery Page',
        path: paths.microbreweryPage[currentLocal],
        component: MicrobreweryPage,
        meta: {
            section: 'microbreweryPage',
            type: 'single',
        }
    },
    {
        name: 'posPage',
        title: 'POS Page',
        path: paths.posPage[currentLocal],
        component: PosPage,
        meta: {
            section: 'posPage',
            type: 'single',
        }
    },
    {
        name: 'restaurantsPage',
        title: 'Restaurants Page',
        path: paths.restaurantsPage[currentLocal],
        component: RestaurantsPage,
        meta: {
            section: 'restaurantsPage',
            type: 'single',
        }
    },
]

// Create the router with the routes
const router = new VueRouter({
    mode: 'history',
    base: currentLocal === defaultLocal ? '/' : `/${currentLocal}`,
    routes,
    linkActiveClass: 'is-active',
})

// Before each route load
router.beforeEach((to, from, next) => {

    store
        .dispatch('global/loadContent', to)
        .then(next)

})

// Link resolver
const linkResolver = (handle) => {

    const route = routes.find(route => route.meta.section === handle)

    if(route) {
        return route.path
    } else {
        return '/'
    }
}


// Export
export default router
export { router, routes, linkResolver }
