import Vue from 'vue'
import Vuex from 'vuex'

import global from './modules/global'
import beers from './modules/beers'
import categories from './modules/categories'
import pages from './modules/pages'
import loader from './modules/loader'

// Vuex
Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        global,
        beers,
        categories,
        pages,
        loader,
    },
    strict: process.env.NODE_ENV !== 'production'
})

export default store
