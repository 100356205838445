/*
** Craft asset transform sizes
** Sizes:
** xs => 0 -> 480
** sm => 480 -> 768
** lg => 768 -> 1200
** xlg => 1200 -> 1920
*/

/** Block content **/
export const content = `
    xs:     url @transform (width: 448)
    xs2x:   url @transform (width: 896)
    sm:     url @transform (width: 448)
    sm2x:   url @transform (width: 896)
    md:     url @transform (width: 475)
    lg:     url @transform (width: 584)
`

export default {
    content
}
