<template>
    <div>
        {{ page.title }}
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Icon from 'objects/Icon';

export default {
    name: 'Home',
    components: {
        Icon,
        AppSection,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seoPage ) ? this.page.seoPage.title : null
        }
    },
};

</script>

<style lang="scss">

.p-home {}

</style>
