<template>
    <div v-if="page">
        <h1>
            {{ page.headline }}
        </h1>

        <div class="grid">
            <div class="grid__row">

                <beer-card
                    v-for="(beer, i) in beersIndex"
                    :key="`beer-${i}`"
                    :beer="beer"
                />
            </div>
        </div>
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import BeerCard from 'components/BeerCard';

export default {
    name: 'BeersPage',
    components: {
        AppSection,
        BeerCard
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seoPage ) ? this.page.seoPage.title : null
        }
    },
    data: () => ({
        beersIndex: []
    }),
    created() {

        // Add all beers to index
        this.$store.dispatch('beers/indexBeers')
            .then(beers => {
                this.updateIndex(beers)
            })

    },
    methods: {
        updateIndex(beers) {

            // Sort with highlighted first
            //beers.sort((a, b) => (a.featured === b.featured) ? 0 : b.featured ? 1 : -1)

            // Update beers index
            this.beersIndex = beers

        },
    }
};

</script>

<style lang="scss">

</style>
