<template>
    <div v-if="page">
        <h1>
            {{ page.title }}
        </h1>
        <div v-html="page.description"></div>
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Icon from 'objects/Icon';

export default {
    name: 'Beer',
    components: {
        Icon,
        AppSection,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seoBeer ) ? this.page.seoBeer.title : null
        }
    },
};

</script>

<style lang="scss">

</style>
