/*
** Cradft GraphQL Queries
*/

import transforms from './asset-transforms'

const siteID = window.__initialData__.siteID || 1




/**********************************
 *
 *
 *  SNIPPETS
 *
 *
 **********************************/

// Default data
const defaultData = `
title
id
slug
`

// Image
const imageData = (size=false) => {
    let data = `
        url
        title
        width
        height
    `

    let transform = transforms[size]

    if (size && typeof transform !== 'undefined') {
        data += `
            ${transform}
            mimeType
        `
    }

    return data
}

// Images
const image = (size) => {
    return `
        image {
            ${imageData(size)}
        }
    `
}
const images = (size) => {
    return `
        images {
            ${imageData(size)}
        }
    `
}

// Base entry content
const entryContent = `
    headline
    seoPage {
        title
        description
    }
`

// Base beer content
const beerContent = `
    availability
    category {
        ${defaultData}
    }
    color
    kind {
        ${defaultData}
    }
    label {
        ${imageData('content')}
    }
    style {
        ${defaultData}
    }
    uri

`




/**********************************
 *
 *
 *  GLOBALS
 *
 *
 **********************************/

// Navigations
export const nav = `{
    globalSet(handle: "navs", siteId: "${siteID}") {
        ... on navs_GlobalSet {
            mainNav {
                ...on mainNav_BlockType {
                    entry {
                        ${defaultData}
                        sectionHandle
                    }
                    label
                }
            }
            altNav {
                ...on altNav_BlockType {
                    entry {
                        ${defaultData}
                        sectionHandle
                    }
                    label
                }
            }
            bookingLink
            orderLink
        }
    }
}`




/**********************************
 *
 *
 *  CATEGORIES
 *
 *
 **********************************/

// Category group
export const categories = (group) => `{
    categories(group: "${group}", siteId: "${siteID}") {
        ${defaultData}
    }
}`




/**********************************
 *
 *
 *  PAGES
 *
 *
 **********************************/

// Homepage
export const homepage = `{
    entry(section: "homepage", siteId: "${siteID}") {
        ${defaultData}
        sectionHandle

        ... on homepage_homepage_Entry {
            ${entryContent}
        }
    }
}`

// Beer Page
export const beersPage = `{
    entry(section: "beersPage", siteId: "${siteID}") {
        ${defaultData}
        sectionHandle

        ... on beersPage_beersPage_Entry {
            ${entryContent}
        }
    }
}`

// Beers (Index)
export const beers = (args) => `{
    entries(section: "beers", ${args ? args + ', ' : ''} siteId: "${siteID}") {
        ${defaultData}
        dateCreated

        ... on beers_beers_Entry {
            ${beerContent}
        }
    }
}`

// Beer (Single)
export const beer = (slug) => `{
    entry(section: "beers", slug: "${slug}", siteId: "${siteID}") {
        ${defaultData}
        sectionHandle

        ... on beers_beers_Entry {
            ${beerContent}
            allergies
            description
            distinctions {
                ...on distinctions_BlockType {
                    label
                }
            }
            hops {
                ${defaultData}
            }
            illustration
            ingredients
            percentage
            tags {
                title
            }
            volume
            yeasts
            seoBeer {
                title
                description
            }
        }
    }
}`

// Blog Page
export const blogPage = `{
    entry(section: "blogPage", siteId: "${siteID}") {
        ${defaultData}
        sectionHandle

        ... on blogPage_blogPage_Entry {
            ${entryContent}
        }
    }
}`

// Contact Page
export const contactPage = `{
    entry(section: "contactPage", siteId: "${siteID}") {
        ${defaultData}
        sectionHandle

        ... on contactPage_contactPage_Entry {
            ${entryContent}
        }
    }
}`

// Microbrewery Page
export const eventsPage = `{
    entry(section: "eventsPage", siteId: "${siteID}") {
        ${defaultData}
        sectionHandle

        ... on eventsPage_eventsPage_Entry {
            ${entryContent}
        }
    }
}`

// Microbrewery Page
export const microbreweryPage = `{
    entry(section: "microbreweryPage", siteId: "${siteID}") {
        ${defaultData}
        sectionHandle

        ... on microbreweryPage_microbreweryPage_Entry {
            ${entryContent}
        }
    }
}`

// POS Page
export const posPage = `{
    entry(section: "posPage", siteId: "${siteID}") {
        ${defaultData}
        sectionHandle

        ... on posPage_posPage_Entry {
            ${entryContent}
        }
    }
}`

// Restaurants Page
export const restaurantsPage = `{
    entry(section: "restaurantsPage", siteId: "${siteID}") {
        ${defaultData}
        sectionHandle

        ... on restaurantsPage_restaurantsPage_Entry {
            ${entryContent}
        }
    }
}`


export default {
    nav,
    categories,
    homepage,
    beersPage,
    beers,
    beer,
    blogPage,
    contactPage,
    eventsPage,
    microbreweryPage,
    posPage,
    restaurantsPage
}
