<template>
    <div
        v-if="url"
        :class="className"
        :style="`--ratio: ${ratio}`"
    >
        <img
            :src="url"
            :alt="title"
            :width="width"
            :height="height"
            class="o-asset__img"
        />
    </div>
</template>

<script>

export default {
    name: 'Asset',
    props: {
        url: String,
        title: String,
        width: Number,
        height: Number,
        cover: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        className() {
            let classname = 'o-asset'
            classname += this.cover ? ' o-asset--cover' : ''
            return classname
        },
        ratio() {
            return (this.width/this.height).toFixed(3)
        }
    }
};

</script>

<style lang="scss">

.o-asset {

    &--cover {

        .o-asset__img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            object-fit: cover;
        }
    }
}

.o-asset__img {
    @include img;
}

</style>
