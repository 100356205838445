<template>
    <div
        class="c-beer-card | grid__col--6 grid__col--4@sm"
        :style="`--color: ${beer.color}`"
    >
        <p>{{ beer.kind[0].title }}</p>
        <h2>{{ beer.title }}</h2>
        <p>{{ realStyle.title }}</p>
        <router-link
            :to="`/${beer.uri}`"
            :title='beer.title'
        >
            Voir
        </router-link>
    </div>
</template>

<script>
import Asset from 'objects/Asset';

export default {
    name: 'BeerCard',
    components: {
        Asset,
    },
    props: {
        beer: {
            type: Object | Array,
            required: true,
        }
    },
    computed: {
        realStyle() {
            return this.beer.style[this.beer.style.length - 1];
        }
    }
}
</script>

<style lang="scss">

.c-beer-card {

    background-color: var(--color);
}

</style>
